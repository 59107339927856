import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import ImageCover from "../components/image-cover"
import Realisation from "../components/realisation"
import LastRealisation from "../components/last-realisation"
import SEO from "../components/seo"
// import helpers from "../scripts/helpers"
// import cssStyle from "../styles/pagepiling.css"

class Design extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <main id="wall">
          
          { /* <section className="scroll-section"> */}
            <VideoCover
              title="L'agence digitale"
              videoUrl="/video/test.mp4" 
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover 
              title="UX / UI Design"
              content="Un veritable lien entre entre l’humain et la machine, l'art UI/UX design comme on le definie chez utopie nous permet de mettre en place des interfaces fonctionnelles et intuitives, qui remplissent leurs promesses, et (surtout) qui conviennent à vos utilisateurs."
              image="/img/hero/uiux.jpg"
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Webdesign"
              content="Nos webdesigners sont là pour vous offrir un site personnalisé, unique, qui vous démarque de vos concurrents et marque vos clients. Nous vous proposons donc une charte graphique personnalisée, adaptée à votre identité existante.
              Couleurs et typographies de votre site Internet et de sa version mobile sont donc rigoureusement choisies."
              image="/img/hero/webdesign.jpg"
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Animation de bannières & Rich-media"
              content="Bannière HTML 5 Créations dynamiques Interstitiel & Lightbox Création expand multidirectionnelle Création déroulante (pushdown) Vidéo"
              image="/img/hero/richmedia.jpg"
            />
          {/* </section> */}

          <LastRealisation />

          <Realisation />

        </main>
      </Layout>
    
  )

  constructor(props) {
    super(props);
    this.state = {
      wall: null
    }
  }

  componentDidMount = () => {
    // helpers.loadCSS(cssStyle, "#pagepilling")
  }

  componentWillUnmount = () => {
    // helpers.unloadCSS("#pagepilling")
  }

}

export default Design
